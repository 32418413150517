
<template>
  <div>
    <v-card >
      <v-tabs
        v-model="tab"
        background-color="deep-purple accent-4"
        left
        dark
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1">
          <v-toolbar-title>
            <slot name="title"></slot>
          </v-toolbar-title>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card flat>
            <v-card-text>
              <Table
                :path="path"
                tableId="vendorOffersTable"
                dbTable="vendor_offers"
                :tableInit="tableInit"
                :showParams="showParams"
                @updateRow="updateActive"
                :filtersFields="filtersFields"
              ></Table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
import {
  TABLE_INIT_URL,
  GET_VENDOR_OFFER_TABLE_INIT,
  UPDATE_VENDOR_OFFER,
} from "@/store/vendorOffer.module";
import Table from "@/views/partials/table/Table.vue";
import moment from "moment";

export default {
  components: {
    Table,
  },
  data() {
    return {
      isProcessing: false,
      showParams: { name: "edit-offer", key: "offer_set_id" },
      path: TABLE_INIT_URL,
      tableInit: GET_VENDOR_OFFER_TABLE_INIT,
      requestLoaded: false,
      tab: "tab-1",
      filtersFields: [
        {
          label: "Company Contact",
          type: "company_contact",
          required: true,
          key: "company_contact_id",
          defaultValue: null,
        },
        {
          label: "Date",
          type: "date",
          required: true,
          key: "date",
          defaultValue: moment().format("YYYY-MM-DD"),
        },
        {
          label: "Expire Date",
          type: "date",
          required: true,
          key: "expire_date",
          defaultValue: moment().add(7, "days").format("YYYY-MM-DD"),
        },
      ],
    };
  },
  mounted() {},
  methods: {
    updateActive(row) {
      this.$store.dispatch(UPDATE_VENDOR_OFFER, {
        id: row.id,
        params: {
          is_active: row.is_active,
        },
      });
    },
  },
};
</script>

<style scoped>
.tag:not(body).is-danger {
  background-color: none;
}
</style>
